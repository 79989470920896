@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');

body {
  margin: 0;
  font-family: 'Pretendard', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* input number arrow - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* input number arrow - Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

/* drawer */
.ant-drawer-content-wrapper {
  width: 375px;
  height: auto !important;
  margin: 0 auto;
  border-radius: 16px 16px 0 0;
}
.ant-drawer-content {
  border-radius: 16px 16px 0 0;
}
.ant-drawer-body {
  padding: 16px 16px 24px !important;
}

/* table */
thead {
  display: none;
}
.ant-table {
  background-color: transparent !important;
}

.Toastify__close-button {
  display: none;
}

/* tooltip */
.ant-tooltip-arrow {
  width: 12px !important;
  height: 12px !important;
  background: #3f73f7 !important;
  transform: rotate(45deg) !important;
  border-radius: 30%;
  left: -4px !important;
  top: 25px !important;
}

.ant-tooltip-arrow::before {
  content: '' !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background: inherit !important;
  border-radius: 2px !important;
}

/* swiper  */
.onBoardingSwiper {
  position: relative;
  height: 100%;
}
.onBoardingSwiper .swiper-pagination {
  bottom: 170px !important;
  height: 10px;
}
.onBoardingSwiper .swiper-pagination-bullet {
  background-color: #3f73f7;
  margin: 0 5px;
}
.onBoardingSwiper .swiper-pagination-bullet-active {
  padding: 0 12px;
  border-radius: 8px;
}
